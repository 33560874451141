import { useToast } from '@/hooks/use-toast'
import http from '@/lib/http'
import type { AdditionalNotesType, MedRequest } from '@/types/data'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/services/queryClient'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useFilterStore } from '@/lib/stores/filter'
import { resetMedicationRequestStore, useMedicationRequestStore } from '@/lib/stores/medication-request'
import { useAISummary } from '@/hooks/useAISummary'

export const invalidateRelevantActionQueries = (patientId: string) => {
  queryClient.invalidateQueries({ queryKey: ['medRequestFiltering'] })
  queryClient.invalidateQueries({
    queryKey: ['patientOrderHistory', patientId]
  })
}

const useMedRequestAction = (props?: {
  doNotResetStore?: boolean
  isBatch?: boolean
  enabled?: boolean
  patientOrigin?: 'medication-request' | 'investigation'
}) => {
  const { toast } = useToast()
  const currentMedication = useCurrentMedication(props?.enabled)
  const { data: aiOutput } = useAISummary(currentMedication?.data)
  const { safeguardingQuestions } = useMedicationRequestStore()

  return useMutation({
    mutationKey: ['medRequestAction'],
    mutationFn: ({
      id,
      action,
      reason,
      changeProduct,
      approveDate,
      additionalNotes,
      extraDirectionsForMedication
    }: {
      id: string
      action: 'approve' | 'reject' | 'hold'
      reason?: string
      changeProduct?: {
        prevSKU: string
        newSKU: string
      } | null
      approveDate?: string
      additionalNotes?: AdditionalNotesType
      extraDirectionsForMedication?: string
    }) => {
      // DO NOT READ STORE DATA DIRECTLY HERE AS IT WILL BE RESETTED BEFORE THIS MUTATION IS CALLED
      return http.post('/prescriber/med-requests/update', {
        id,
        action,
        additionalNotes,
        extraDirectionsForMedication,
        reason,
        changeProduct,
        approveDate,
        safeguardingQuestions,
        ...(aiOutput ? { aiOutput } : {})
      })
    },
    onMutate(variables) {
      const key = useFilterStore.getState().getCacheKey()
      queryClient.setQueryData(key, (old: MedRequest[]) => old.filter(e => e.id !== variables.id))

      if (!props?.doNotResetStore) {
        resetMedicationRequestStore()
      }
      // TODO consider setting a new data for the store here (this way we can remove useEffects and setState/rednering will be done only once instead of rendering with empty data and then updating the page)
    },
    onSuccess() {
      if (!props?.isBatch && currentMedication?.data) {
        invalidateRelevantActionQueries(currentMedication.data.patient.id)
      }
    },
    onError() {
      toast({
        title: 'Error',
        description: 'Error approving medication request'
      })
    }
  })
}

export default useMedRequestAction
