import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import TextareaWithLabel from '../textarea'
import useMedRequestAction from '@/lib/api/med-requests/action'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { cn, medReqFieldValidationNotifications } from '@/lib/utils'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useState } from 'react'
import SingleCombobox from '@/components/common/single-combobox'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { useInvestigationStore } from '@/lib/stores/investigation'
import useInvestigationAction from '@/lib/api/investigations/action'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { useGoNextInvestigation } from '@/hooks/useGoNextInvestigation'
import { useUser } from '@clerk/clerk-react'
import { useGetMessagingTemplate } from '@/hooks/useGetMessagingTemplate'
import InvestigationTemplatePreview from '../../investigation/template-preview'
import { usePosthogCustom } from '@/hooks/usePosthogCustom'

const ContactPatientSection = ({
  patientOrigin = 'medication-request'
}: {
  patientOrigin?: 'medication-request' | 'investigation'
}) => {
  const {
    setSidebarView: setMedicationSidebarView,
    contactMessage: medicationContactMessage,
    setContactMessage: setMedicationContactMessage,
    medicationDirection
  } = useMedicationRequestStore()
  const {
    setSidebarView: setInvestigationSidebarView,
    contactMessage: investigationContactMessage,
    setContactMessage: setInvestigationContactMessage
  } = useInvestigationStore()

  const isMedReq = patientOrigin === 'medication-request'
  const setSidebarView = isMedReq ? setMedicationSidebarView : setInvestigationSidebarView
  const setContactMessage = isMedReq ? setMedicationContactMessage : setInvestigationContactMessage
  const contactMessage = isMedReq ? medicationContactMessage : investigationContactMessage
  const fieldValidationNotifications = isMedReq ? medReqFieldValidationNotifications : () => true

  const goNextMedRequest = useGoNextMedRequest(patientOrigin === 'medication-request')
  const goNextInvestigation = useGoNextInvestigation(patientOrigin === 'investigation')
  const goNextRequest = isMedReq ? goNextMedRequest : goNextInvestigation

  const medReqAction = useMedRequestAction({
    enabled: !!(patientOrigin === 'medication-request')
  })
  const investigationAction = useInvestigationAction()
  const action = isMedReq ? medReqAction : investigationAction

  const [template, setTemplate] = useState('')

  const { trackMedRequestAction } = usePosthogCustom()

  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const medRequest = useCurrentMedication(
    !!(patientOrigin === 'medication-request' || investigation?.data?.medicationRequestId),
    investigation?.data?.medicationRequestId,
    isMedReq
  )
  const { templateOptions } = useQuestionnaire(medRequest?.data?.questionaire ?? '')

  const { user } = useUser()

  const {
    data: retestTemplate,
    isLoading,
    error
  } = useGetMessagingTemplate({
    vendorHash: investigation.data?.vendorHash ?? '',
    templateName: 'conversation-retest',
    data: {
      patientName: investigation.data?.patient.firstName ?? '',
      prescriberName: user?.fullName ?? ''
    },
    enabled: Boolean(!isMedReq && investigation?.data)
  })

  const data = medRequest?.data

  const isDisabled = (isMedReq && !contactMessage) || action.isPending || (!isMedReq && !retestTemplate)

  if (!data) return null

  return (
    <div className='sticky top-[88px] max-w-[300px] h-fit flex min-w-[25vw] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6'>
        <p className='mb-2 text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>
          {isMedReq ? 'Follow up' : 'Contact'}
        </p>
        {isMedReq && (
          <p className='text-custom-neutral-500 text-sm leading-[170%]'>
            {isMedReq
              ? 'Reach out to the patient if you have any questions.'
              : 'Notify the customer support team they need to contact the patient.'}
          </p>
        )}
      </div>
      <div className='p-6'>
        {isMedReq && (
          <>
            <p className='mb-4 text-[#1D1D1D] font-medium leading-[170%]'>Select a template</p>
            <SingleCombobox
              isDisabled={templateOptions?.onHoldOptions.length === 0}
              title='Templates'
              defaultValue={template}
              onValueChange={value => {
                setContactMessage(value)
                setTemplate(value)
              }}
              options={templateOptions?.onHoldOptions ?? []}
            />
          </>
        )}
        {isMedReq && (
          <div className='mt-4 mb-12'>
            <TextareaWithLabel value={contactMessage} onChange={e => setContactMessage(e.target.value)} autoFocus />
          </div>
        )}
        {!isMedReq && (
          <InvestigationTemplatePreview template={retestTemplate} isLoading={isLoading} error={Boolean(error)} />
        )}
        <button
          type='button'
          disabled={isDisabled}
          onClick={() => {
            if (!fieldValidationNotifications('hold')) return

            if (isMedReq) {
              medReqAction.mutate({
                id: data.id,
                action: 'hold',
                reason: contactMessage
              })

              trackMedRequestAction('contact', data.id)
            } else {
              if (!investigation.data) return
              investigationAction.mutate({
                id: investigation.data.id,
                action: 'contact',
                message: retestTemplate ?? ''
              })
            }
            goNextRequest()
          }}
          className={cn(
            'flex justify-center items-center gap-2 mb-4 h-14 rounded-2xl bg-[#153CB7] text-white text-sm font-semibold leading-[170%] w-full transition-all duration-200 ease-in-out',
            isDisabled && 'opacity-50 cursor-not-allowed',
            action.isPending && 'cursor-wait opacity-70'
          )}
        >
          Submit
        </button>
        <button
          type='button'
          onClick={() => {
            setSidebarView('navigation-menu')
            setContactMessage('')
          }}
          className='flex h-14 w-full color-[#1D1D1D] items-center justify-center gap-4 rounded-2xl border border-custom-neutral-200 bg-white px-6 text-custom-neutral-600 text-sm font-semibold  hover:bg-custom-neutral-200 transition-colors duration-200
        leading-[170%]'
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ContactPatientSection
