import posthog from 'posthog-js'
import { PosthogEvent } from '@/types/posthog'
import { useUser } from '@clerk/clerk-react'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'

export function usePosthogCustom() {
  const { user } = useUser()

  const { sessionStartTime } = useMedicationRequestStore()

  const trackMedRequestAction = (action: 'approve' | 'reject' | 'contact', medReqId: string) => {
    const event =
      action === 'approve'
        ? PosthogEvent.MED_REQUEST_APPROVED
        : action === 'contact'
          ? PosthogEvent.MED_REQUEST_CONTACT
          : PosthogEvent.MED_REQUEST_REJECTED

    const secondsToAction = Math.round((new Date().getTime() - sessionStartTime.getTime()) / 1000)

    posthog?.capture(event, {
      med_req_id: medReqId,
      seconds_to_action: secondsToAction,
      prescriber: user?.fullName
    })
  }

  return { trackMedRequestAction }
}
