import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import useMedRequestAction from '@/lib/api/med-requests/action'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import TextareaWithLabel from '../textarea'
import { cn, medReqFieldValidationNotifications } from '@/lib/utils'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useState } from 'react'
import SingleCombobox from '@/components/common/single-combobox'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { usePosthogCustom } from '@/hooks/usePosthogCustom'

const RejectionReasonSection = () => {
  const { setSidebarView, rejectionReason, medicationDirection, setRejectionReason } = useMedicationRequestStore()
  const goNextMedRequest = useGoNextMedRequest()
  const action = useMedRequestAction()
  const [template, setTemplate] = useState('')

  const medRequest = useCurrentMedication()
  const data = medRequest?.data

  const { templateOptions } = useQuestionnaire(data?.questionaire ?? '')

  const { trackMedRequestAction } = usePosthogCustom()

  if (!data) return null

  return (
    <div className='sticky top-[88px] h-fit flex min-w-[25vw] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6'>
        <p className='mb-2 text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Rejection Reason</p>
        <p className='text-custom-neutral-500 text-sm leading-[170%]'>
          Provide the reasons for rejecting the medication request.
        </p>
      </div>
      <div className='p-6'>
        <p className='mb-4 text-[#1D1D1D] font-medium leading-[170%]'>Select a template</p>
        <SingleCombobox
          isDisabled={templateOptions?.rejectionOptions.length === 0}
          title='Templates'
          defaultValue={template}
          onValueChange={value => {
            setRejectionReason(value)
            setTemplate(value)
          }}
          options={templateOptions?.rejectionOptions ?? []}
        />
        <div className='mt-4 mb-12'>
          <TextareaWithLabel value={rejectionReason} onChange={e => setRejectionReason(e.target.value)} autoFocus />
        </div>
        <button
          type='button'
          onClick={() => {
            if (!medReqFieldValidationNotifications('reject')) return

            action.mutate({
              id: data.id,
              action: 'reject',
              reason: rejectionReason,
              extraDirectionsForMedication: medicationDirection
            })

            trackMedRequestAction('reject', data.id)
            goNextMedRequest()
          }}
          className={cn(
            'flex justify-center items-center gap-2 mb-4 h-14 rounded-2xl bg-[#153CB7] text-white text-sm font-semibold leading-[170%] w-full transition-all duration-200 ease-in-out',
            !rejectionReason && 'opacity-50 cursor-not-allowed',
            action.isPending && 'cursor-wait opacity-70'
          )}
          disabled={action.isPending || !rejectionReason}
        >
          {action.isPending ? (
            <>
              <LoadingSpinner className='w-6 h-6 text-white animate-spin' />
              <span>Loading..</span>
            </>
          ) : (
            'Submit'
          )}
        </button>
        <button
          type='button'
          onClick={() => {
            setSidebarView('navigation-menu')
            setRejectionReason('')
          }}
          className='flex h-14 w-full items-center justify-center gap-4 rounded-2xl border border-custom-neutral-200 bg-white px-6 text-custom-neutral-600 text-sm font-semibold leading-[170%]'
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default RejectionReasonSection
