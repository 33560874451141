import InfoRounded from '@/components/ui/icons/info-rounded'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import { useScrAiSummary } from '@/hooks/scr-ai-summary'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import type { SCRData } from '@/lib/stores/medication-request'
import { cn, getAiRespTextClassName } from '@/lib/utils'
import { useFlag } from '@unleash/proxy-client-react'

function Section({
  header,
  id,
  data
}: {
  header: string
  id?: string
  data: SCRData[] | string[]
}) {
  const isDataString = typeof data === 'string'
  return (
    <div id={id}>
      <div className='flex h-[59px] p-4 px-6 items-center rounded-2xl border border-custom-neutral-200 bg-custom-gray-3'>
        <p className='text-[#080F30] text-base font-semibold leading-[170%]'>{header}</p>
      </div>
      <ul className='pt-4 px-12 list-disc'>
        {isDataString ? (
          <li className='text-custom-neutral-800 text-base font-normal leading-[170%] mb-4'>
            <span>{data}</span>
          </li>
        ) : (
          data.map((item, index) => {
            //	console.log("item", item);
            const isItemString = typeof item === 'string'

            const [date, ...rest] = Object.values(item)

            return (
              <li key={index} className='text-custom-neutral-800 text-base font-normal leading-[170%] mb-4'>
                {isItemString ? (
                  <span>{item}</span>
                ) : (
                  <>
                    <span className='font-semibold'>{date}: </span>
                    {rest.join(', ')}
                  </>
                )}
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}

export default function SCRDataSection() {
  const shouldGetScrSummary = useFlag('scr-summary')
  const fieldNamesToSkip = ['nhs_number', 'postcode']

  const medRequest = useCurrentMedication()
  if (!medRequest) return null
  const { data } = medRequest

  const scrData = (data?.patient?.scrData && data.patient.scrData !== 'Summary Care Record not found') ? JSON.parse(data.patient.scrData) : null

  const { mainText, attentionText, isLoading } = useScrAiSummary({
    medicationRequestId: data?.id ?? '',
    enabled: !!data && shouldGetScrSummary && !!scrData && !scrData.error
  })

  if (!scrData) return null

  return (
    <div className='flex flex-col gap-[5.3rem]'>
      {!scrData.error && (
        <div className='flex flex-col gap-6'>
          <div className='flex items-center gap-2'>
            <h2 className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>SCR Data Summary</h2>
            {isLoading && <LoadingSpinner className='w-6 h-6 text-custom-neutral-900 animate-spin' />}
          </div>

          {!!mainText.length && (
            <div className='flex flex-col gap-4'>
              <div className='flex items-center bg-[#FAFAFB] border border-[#E9EAED] py-4 px-6 gap-6 rounded-3xl'>
                <InfoRounded className='min-w-6 min-h-6' />
                <span className='text-base leading-7 font-poppins'>
                  Please note, this summary has been generated by AI and may be incorrect. Prescribers are clinically
                  required to read the full SCR data before making a decision.
                </span>
              </div>
              <div className='flex flex-col bg-[#FAFAFB] border border-[#E9EAED] p-8 gap-6 rounded-3xl'>
                <div className='flex flex-col gap-1'>
                  {mainText.map((text, idx) => (
                    <p
                      key={idx}
                      className={cn(
                        'text-custom-neutral-800 text-sm font-normal leading-5',
                        getAiRespTextClassName({
                          allText: mainText,
                          index: idx
                        })
                      )}
                    >
                      {text}
                    </p>
                  ))}
                </div>

                {attentionText?.length > 0 && (
                  <div className='rounded-3xl border border-[#FF8080] flex flex-col'>
                    <h3 className='font-poppins font-medium text-base leading-7 px-4 py-3 bg-[#FFE5E5] text-[#1D1D1D] rounded-t-3xl'>
                      Patient flags that may need attention
                    </h3>
                    <div className='flex flex-col gap-1 p-4'>
                      {attentionText.map((text, idx) => (
                        <p
                          key={idx}
                          className={cn(
                            'text-custom-neutral-800 text-sm font-normal leading-5',
                            getAiRespTextClassName({
                              allText: attentionText,
                              index: idx
                            })
                          )}
                        >
                          {text}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className='flex flex-col gap-6'>
        <h2 className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>SCR Data</h2>
        <div className='flex flex-col gap-6'>
          {Object.entries(scrData).map(([key, value]) => {
            if (fieldNamesToSkip.includes(key)) {
              return null
            }

            return (
              <Section
                key={key}
                data={value as SCRData[] | string[]}
                id={`scr-section-${key}`}
                header={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
