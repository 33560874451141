import AdditionalNotes from './sections/additional-notes'
import Consultation from './sections/consultation'
import ContactPatientSection from './sections/contact-patient-section'
import Footer from './sections/footer'
import NavigationMenu from './sections/nav-menu'
import OnHoldHistory from './sections/on-hold-history'
import PatientData from './sections/patient-data'
import PrescriptionDetails from './sections/prescription-details'
import PreviousOrders from './sections/previous-orders'
import RejectionReasonSection from './sections/rejection-reason-section'

import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { isMedRequestInEditableStage, isRoleAllowedToAction, isRoleAllowedToEdit } from '@/lib/utils'
import PostOrderView from './post-order-view'
import ApprovalReasonSection from './sections/approval-reason-section'
import Header from './sections/header'
import SCRDataSection from './sections/scr-data'
import { useUser } from '@clerk/clerk-react'
import PreviousInvestigations from './sections/previous-investigations'
import { useAISummary } from '@/hooks/useAISummary'
import SafeguardingQuestions from './sections/safeguarding-questions'

const MedicationRequestPage = () => {
  const { sidebarView, status } = useMedicationRequestStore()
  const { user } = useUser()

  const medRequest = useCurrentMedication()
  const data = medRequest?.data

  const isEditable =
    (data && isMedRequestInEditableStage(data.stage) && isRoleAllowedToEdit(data, user?.publicMetadata.roles ?? [])) ??
    false

  const success = status !== 'pending'

  const hideFooter =
    sidebarView !== 'navigation-menu' || !isEditable || !isRoleAllowedToAction(user?.publicMetadata.roles ?? [], data)

  const { data: aiOutput } = useAISummary(data)

  if (success) return <PostOrderView />

  if (!data) return null

  return (
    <section key={data.id} className='flex flex-col gap-8'>
      <Header />
      {aiOutput && (
        <div className='rounded-lg border border-custom-neutral-200 bg-custom-gray-3 p-6'>
          <b>AI Summary:</b>{' '}
          <span className='text-custom-neutral-600 text-base leading-relaxed whitespace-pre-wrap'>{aiOutput}</span>
        </div>
      )}

      <div className='flex gap-[37px] flex-nowrap w-full justify-between'>
        <div className='flex flex-col gap-14 w-full'>
          <PatientData />
          <ErrorBoundary fallback='Error Loading Safeguarding Questions'>
            <SafeguardingQuestions isEditable={isEditable} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error Loading Previous Orders'>
            <PreviousOrders patientId={data.patient.id} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error Loading Previous Investigations'>
            <PreviousInvestigations patientId={data.patient.id} />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading On Hold History'>
            <OnHoldHistory />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading Consultation Data'>
            <Consultation />
          </ErrorBoundary>
          <ErrorBoundary fallback='Error loading SCR Data'>
            <SCRDataSection />
          </ErrorBoundary>
          <PrescriptionDetails isEditable={isEditable} />
          <AdditionalNotes isEditable={isEditable} />
        </div>
        {sidebarView === 'navigation-menu' && (
          <ErrorBoundary fallback='Error loading Navigation Menu'>
            <NavigationMenu patientId={data.patient.id} />
          </ErrorBoundary>
        )}
        {sidebarView === 'approval-reason' && <ApprovalReasonSection />}
        {sidebarView === 'contact-patient' && <ContactPatientSection />}
        {sidebarView === 'rejection' && <RejectionReasonSection />}
      </div>
      {!hideFooter && <Footer />}
    </section>
  )
}

export default MedicationRequestPage
