import { useQuery } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import http from '@/lib/http'

interface UseScrAiSummaryProps {
  medicationRequestId: string
  enabled?: boolean
}

export const useScrAiSummary = ({ medicationRequestId, enabled = true }: UseScrAiSummaryProps) => {
  const fullTextRef = useRef('')
  const [isLoading, setIsLoading] = useState(false)
  const [mainText, setMainText] = useState<string[]>([])
  const [attentionText, setAttentionText] = useState<string[]>([])

  const query = useQuery({
    queryKey: ['scrAiSummary', medicationRequestId],
    queryFn: async () => {
      setIsLoading(true)

      const stream = (await http.get(`/prescriber/scr-ai/${medicationRequestId}`, {
        responseType: 'stream'
      })) as AsyncIterable<Uint8Array>

      for await (const chunk of stream) {
        const text = String.fromCharCode.apply(null, chunk as unknown as number[])
        try {
          fullTextRef.current += text
          if (typeof fullTextRef.current !== 'undefined') {
            let [mainText, attentionText] = fullTextRef.current.split('<PATIENT_FLAGS_MAY_NEED_ATTENTION>')

            if (attentionText) {
              attentionText = attentionText
                .replace('<PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
                .replace('</PATIENT_FLAGS_MAY_NEED_ATTENTION>', '')
            }

            setMainText(mainText.split('\n'))
            setAttentionText(attentionText.split('\n'))
          }
        } catch (error) {
          console.error({ error })
        }
      }

      fullTextRef.current = ''
      setIsLoading(false)
      return fullTextRef.current
    },
    enabled
  })

  return {
    ...query,
    isLoading,
    mainText,
    attentionText
  }
}
