import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import useMedRequestAction from './action'
import useBatchApproval from '@/hooks/useBatchApproval'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { useFlag } from '@unleash/proxy-client-react'
import { usePosthogCustom } from '@/hooks/usePosthogCustom'

export default function useApproveMedicationRequest(props?: {
  patientOrigin?: 'medication-request' | 'investigation'
}) {
  const { patientOrigin = 'medication-request' } = props ?? {}

  const { medication, medicationIssueDate } = useMedicationRequestStore()
  const goNextMedRequest = useGoNextMedRequest(patientOrigin === 'medication-request')

  const medRequest = useCurrentMedication(patientOrigin === 'medication-request')
  const originalMedRequest = medRequest?.data

  const { parsedQuestions } = useQuestionnaire(originalMedRequest?.questionaire ?? '')
  const hasFlags = parsedQuestions?.some(question => question.hasFlags)

  const isBatchApprovalEnabled = useFlag('batch-approval')

  const { data: batchApprovals } = useBatchApproval({
    medRequestId: originalMedRequest?.id ?? '',
    enabled: !!(originalMedRequest?.id && !hasFlags && isBatchApprovalEnabled)
  })

  const shouldUseBatchApprovalLogic = isBatchApprovalEnabled && !!batchApprovals?.length && !hasFlags

  const { trackMedRequestAction } = usePosthogCustom()

  const action = useMedRequestAction({
    enabled: !!(patientOrigin === 'medication-request'),
    doNotResetStore: shouldUseBatchApprovalLogic
  })

  return () => {
    if (!originalMedRequest) return

    let changeProduct = undefined
    if (
      typeof medication?.wooSKU === 'string' &&
      typeof originalMedRequest?.wooSKU === 'string' &&
      medication.wooSKU !== originalMedRequest?.wooSKU
    ) {
      changeProduct = {
        prevSKU: originalMedRequest?.wooSKU,
        newSKU: medication.wooSKU
      }
    }

    const { notes, medicationDirection, setStatus, setBatchOrders, justification, setJustification } =
      useMedicationRequestStore.getState()
    action.mutate({
      id: originalMedRequest.id,
      action: 'approve',
      reason: justification,
      approveDate: medicationIssueDate?.toISOString(),
      additionalNotes: notes,
      extraDirectionsForMedication: medicationDirection,
      changeProduct
    })

    trackMedRequestAction('approve', originalMedRequest.id)

    // Move to batch approval screen.
    if (shouldUseBatchApprovalLogic) {
      setBatchOrders(batchApprovals)
      setStatus('approved')
    } else {
      goNextMedRequest()
      setJustification('')
    }
  }
}
