import { useMedicationRequestById } from '@/services/medicationRequests'
import { useParams } from 'react-router-dom'
import usePrepareNextRequest from './usePrepareNextRequest'
import { useLockItemById } from '@/services/useLockItem'
import { useUser } from '@clerk/clerk-react'
import { shouldLockMedRequest } from '@/lib/utils'

export const useCurrentMedication = (enabled?: boolean, customId?: string, prepareNext = true) => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    throw new Error('No ID provided')
  }
  const medRequest = useMedicationRequestById(customId ?? id, enabled)

  const { user } = useUser()
  const lockMedRequest = medRequest?.data
    ? shouldLockMedRequest(medRequest.data, user?.publicMetadata.roles ?? [])
    : false

  useLockItemById(customId ?? id, !!(medRequest?.data && !medRequest?.data?.locked && enabled) && lockMedRequest)
  usePrepareNextRequest(enabled && prepareNext)

  return medRequest
}
